import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import BundleBenefit from 'components/modules/Shared/BundleBenefit';
import clsx from 'classnames';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  bonusRoot: {
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  bonusTitle: {
    color: palette.primary.main,
    [breakpoints.up('md')]: {
      fontSize: 18,
      fontWeight: 600,
    },
    [breakpoints.up('lg')]: {
      fontSize: 20,
    },
  },
  normalFont: {
    fontWeight: 'normal',
  },
  customText: {
    [breakpoints.up('md')]: {
      fontSize: 18,
    },
    [breakpoints.up('lg')]: {
      fontSize: 20,
    },
  },
  fixedSaverTwo: {
    marginTop: 24,
  },
}));

const PriceCapBonus = ({ serviceCount }) => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      {serviceCount === 1 && "Always cheaper than the government's price cap"}
      {serviceCount === 2 && "£20 off the government's price cap"}
      {serviceCount >= 3 && "£50 off the government's price cap"}
    </BundleBenefit>
  );
};

const Extra50BonusTenant = () => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      <span className={classes.normalFont}>
        You're accepting a free smart meter upgrade
      </span>
    </BundleBenefit>
  );
};

const SavingsBonus = () => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      Variable tariff with no exit fees
    </BundleBenefit>
  );
};

const OnlineTopupsBonus = () => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      Online top ups available via App for smart meter customers
    </BundleBenefit>
  );
};

const VariableTariffBonus = () => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      Variable tariff with no contract
    </BundleBenefit>
  );
};

const BestTariffBonus = () => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      Our best priced tariff
    </BundleBenefit>
  );
};

const PricesFixedBonus = ({ date }) => {
  const classes = useStyles();
  return (
    <BundleBenefit
      titleVariant="body1"
      bundleBenefitClasses={{
        root: classes.bonusRoot,
        title: classes.bonusTitle,
      }}
    >
      Prices fixed until {date}
    </BundleBenefit>
  );
};
PricesFixedBonus.propTypes = {
  date: PropTypes.string,
};

export const BonusesVariable = ({ serviceCount }) => {
  const classes = useStyles();
  return (
    <>
      <PriceCapBonus serviceCount={serviceCount} />
      <SavingsBonus />
      <Typography
        variant="body1"
        color="primary"
        classes={{ root: classes.customText }}
      >
        Your energy prices change in line with our costs. We’ll always give you
        plenty of notice before any change.
      </Typography>
    </>
  );
};
BonusesVariable.propTypes = {
  serviceCount: PropTypes.number,
};

export const BonusesPrepayment = () => (
  <>
    <VariableTariffBonus />
    <OnlineTopupsBonus />
  </>
);

export const BonusesFixed = ({ date = '', fixedMoreThanTwoToggle }) => {
  const classes = useStyles();
  return (
    <>
      <PricesFixedBonus date={date} />
      {fixedMoreThanTwoToggle && <BestTariffBonus />}
      <Typography
        variant="body1"
        color="primary"
        // NOTE: we add 24px margin to the top of the element when there ISN"T more than 2 services so it needs to be!fixedMoreThanTwoToggle
        classes={{
          root: clsx(
            classes.customText,
            !fixedMoreThanTwoToggle && classes.fixedSaverTwo
          ),
        }}
      >
        Protect yourself against price changes, even if our costs go up.
      </Typography>
    </>
  );
};
BonusesFixed.propTypes = {
  date: PropTypes.string,
};

export const BonusesFixedTenant = ({ date = '', fixedMoreThanTwoToggle }) => {
  const classes = useStyles();
  return (
    <>
      <PricesFixedBonus date={date} />
      {fixedMoreThanTwoToggle && <BestTariffBonus />}
      <Extra50BonusTenant />
      <Typography
        variant="body1"
        color="primary"
        // NOTE: we add 24px margin to the top of the element when there ISN"T more than 2 services so it needs to be!fixedMoreThanTwoToggle
        classes={{
          root: clsx(
            classes.customText,
            !fixedMoreThanTwoToggle && classes.fixedSaverTwo
          ),
        }}
      >
        Protect yourself against price changes, even if our costs go up.
      </Typography>
    </>
  );
};
BonusesFixedTenant.propTypes = {
  date: PropTypes.string,
};
